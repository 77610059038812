import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./Hero.module.css";
import myImage from "../img/myImage.png";

const words = [
  "designing solutions.",
  "discovering insights.",
  "driving success.",
  "datacook.",
];

function Hero({ className, openContactForm }) {
  const [currentWord, setCurrentWord] = useState(0);
  const [isTyping, setIsTyping] = useState(true);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    if (isPaused) return;

    const wordChangeInterval = setInterval(() => {
      setIsTyping(false);
      setTimeout(() => {
        setCurrentWord((prevWord) => (prevWord + 1) % words.length);
        setIsTyping(true);
      }, 250); // Duration of the fade-out animation
    }, 2000); // Interval for changing words

    return () => {
      clearInterval(wordChangeInterval);
    };
  }, [isPaused]);

  useEffect(() => {
    if (words[currentWord] === "datacook.") {
      setIsPaused(true);
      const pauseTimer = setTimeout(() => {
        setIsPaused(false);
      }, 6000); // Pause for 6 seconds

      return () => clearTimeout(pauseTimer);
    }
  }, [currentWord]);

  return (
    <section className={`${styles.hero} ${className}`}>
      <div className={styles.heroContent}>
        <div className={styles.heroLeft}>
          <h3 className={styles.cdHeadline}>
            <span
              className={`${isTyping ? styles.typing : styles.notTyping} ${
                words[currentWord] === "datacook." ? styles.underline : ""
              }`}
            >
              {words[currentWord]}
            </span>
          </h3>
          <h1 className={styles.heroHeadline}>
            <b>Ihr Partner für Software nach Maß.</b>
          </h1>
          <div className={styles.heroDescription}>
            <p>
              Wir helfen Unternehmen, ihre Geschäftsprozesse in das digitale
              Zeitalter zu überführen. Mit unseren Beratungsleistungen und maßgeschneiderten
              Softwarelösungen sparen Sie nicht nur
              Zeit, sondern steigern nachhaltig Ihre Produktivität.
            </p>
          </div>
          <button onClick={openContactForm} className={styles.heroCta}>
            Anfrage senden
          </button>
        </div>
        <div className={styles.heroRight}>
          <img src={myImage} alt="My Portrait" className={styles.heroImage} />
          <div className={styles.heroName}>
            <h2>Charles David Mupende</h2>
            <p>Gründer</p>
          </div>
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = {
  className: PropTypes.string,
  openContactForm: PropTypes.func.isRequired,
};

export default Hero;
