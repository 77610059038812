import React, { useState, useEffect, useRef } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import styles from "./ImageCarousel.module.css";
import { slides } from "../contents/slidesData";

function ImageCarousel() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: true,
      slides: { perView: 1 },
      initial: 0,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },
    },
    [
      (slider) => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 6000);
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      },
    ]
  );

  return (
    <div ref={sliderRef} className={`keen-slider ${styles.carouselContainer}`}>
      {slides.map((slide, index) => (
        <div key={index} className={`keen-slider__slide ${styles.slide}`}>
          <img
            src={slide.image}
            alt={`Slide ${index + 1}`}
            className={styles.image}
          />
          <div className={styles.overlay}>
            <h2 className={styles.title}>{slide.title}</h2>
            <p className={styles.content}>{slide.content}</p>
            <button className={styles.button}>{slide.button}</button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ImageCarousel;
