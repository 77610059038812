import React, { useState, useEffect, useRef } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import styles from "./CustomCarousel.module.css";

function CustomCarousel({ title, slides }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: true,
    mode: "free-snap",
    slides: {
      perView: 3,
      spacing: 15,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    breakpoints: {
      "(max-width: 1024px)": {
        slides: { perView: 2, spacing: 10 },
      },
      "(max-width: 600px)": {
        slides: { perView: 1, spacing: 10 },
      },
    },
  });

  useEffect(() => {
    const autoplay = setInterval(() => {
      if (instanceRef.current) {
        instanceRef.current.next();
      }
    }, 4000); // Change slide every 4 seconds

    return () => {
      clearInterval(autoplay);
    };
  }, [instanceRef]);

  return (
    <div className={styles.carouselWrapper}>
      <h2 className={styles.componentHeading}>{title}</h2>
      <div
        ref={sliderRef}
        className={`keen-slider ${styles.carouselContainer}`}
      >
        {slides.map((slide, index) => (
          <div key={index} className={`keen-slider__slide ${styles.slide}`}>
            <img src={slide.image} alt={slide.title} className={styles.image} />
            <div className={styles.overlay}>
              <h3 className={styles.title}>{slide.title}</h3>
              <p className={styles.content}>{slide.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CustomCarousel;
