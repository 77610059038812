// src/App.js

import React, { useState } from "react";
import "./App.css";
import LayoutWrapper from "./components/LayoutWrapper";
import Hero from "./components/Hero";
import ImageCarousel from "./components/ImageCarousel";
import CustomCarousel from "./components/CustomCarousel";
import { leistungenSlides } from "./contents/leistungenData";
import VerticalProcess from "./components/VerticalProcess";
import ClickSparkWrapper from "./components/ClickSparkWrapper";
import FadeIn from "react-fade-in";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ContactForm from "./components/ContactForm";
import ContentPage from "./components/ContentPage";
import { impressumContent } from "./contents/impressumData";
import { potenzialanalyseContent } from "./contents/potenzialanalyseData";
import { digitalisierungsberatungContent } from "./contents/digitalisierungsberatungData";
import { businessIntelligenceContent } from "./contents/businessintelligenceData";
import ArticlePage from "./components/ArticlePage";
import BrandCarousel from "./components/BrandCarousel";
import NotFoundPage from "./components/NotFoundPage";

function HomePage({ openContactForm }) {
  return (
    <FadeIn delay={150}>
      <ImageCarousel />
      <Hero openContactForm={openContactForm} />
      {/* <CustomCarousel title="Leistungen" slides={leistungenSlides} /> */}
      <VerticalProcess openContactForm={openContactForm} />
      <BrandCarousel />
    </FadeIn>
  );
}

function App() {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);

  const openContactForm = () => setIsContactFormOpen(true);
  const closeContactForm = () => setIsContactFormOpen(false);

  return (
    <Router>
      {/* <ClickSparkWrapper /> */}
      <LayoutWrapper openContactForm={openContactForm}>
        <div className="content-wrapper">
          <div className="content">
            <Routes>
              <Route
                path="/"
                element={<HomePage openContactForm={openContactForm} />}
              />
              <Route
                path="/impressum"
                element={
                  <ContentPage
                    title={impressumContent.title}
                    sections={impressumContent.sections}
                  />
                }
              />
              <Route
                path="/potenzialanalyse"
                element={
                  <ArticlePage
                    title={potenzialanalyseContent.title}
                    subtitle="Eine detaillierte Untersuchung"
                    date="2024-07-04" // ISO format for the date
                    image="https://images.unsplash.com/photo-1496504175726-c7b4523c7e81?q=80&w=2117&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" // use the uploaded image path
                    sections={potenzialanalyseContent.sections}
                  />
                }
              />
              <Route
                path="/digitalisierungsberatung"
                element={
                  <ArticlePage
                    title={digitalisierungsberatungContent.title}
                    subtitle={digitalisierungsberatungContent.subtitle}
                    date={digitalisierungsberatungContent.date}
                    image={digitalisierungsberatungContent.image}
                    sections={digitalisierungsberatungContent.sections}
                  />
                }
              />
              <Route
                path="/business-intelligence"
                element={
                  <ArticlePage
                    title={businessIntelligenceContent.title}
                    subtitle={businessIntelligenceContent.subtitle}
                    date={businessIntelligenceContent.date}
                    image={businessIntelligenceContent.image}
                    sections={businessIntelligenceContent.sections}
                  />
                }
              />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
        </div>
      </LayoutWrapper>
      <ContactForm isOpen={isContactFormOpen} onClose={closeContactForm} />
    </Router>
  );
}

export default App;
