import React, { useState, useEffect, useRef } from "react";
import styles from "./VerticalProcess.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { steps } from "../contents/stepsData";

const VerticalProcess = ({ openContactForm }) => {
  const stepRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.visible);
          }
        });
      },
      { threshold: 0.1 }
    );

    stepRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      stepRefs.current.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, []);

  return (
    <div className={styles.processWrapper}>
      <h2 className={styles.title}>Projektablauf</h2>
      <p className={styles.subtitle}>
        Jedes Projekt ist einzigartig und bringt neue Erfahrungen mit sich, und
        wir sind Ihr verlässlicher Partner in jeder Phase.
      </p>
      <div className={styles.verticalLine}></div>
      {steps.map((step, index) => (
        <div
          key={index}
          className={`${styles.step} ${
            index % 2 === 0 ? styles.left : styles.right
          }`}
          ref={(el) => (stepRefs.current[index] = el)}
        >
          <div className={styles.stepItem}>
            <FontAwesomeIcon icon={step.icon} className={styles.stepImage} />
            <div className={styles.stepContent}>
              <div className={styles.stepText}>
                <h3>{step.title}</h3>
                <p>{step.description}</p>
              </div>
            </div>
          </div>

          <div className={styles.dot}></div>
        </div>
      ))}
      <button onClick={openContactForm} className={styles.heroCta}>
        Anfrage senden
      </button>
    </div>
  );
};

export default VerticalProcess;
