import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Collapse,
  ListSubheader,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import styles from "./Header.module.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { menuItems, submenuData } from "../contents/submenuData";
import Fade from "@mui/material/Fade";

function Header({ theme, toggleTheme, isVisible, openContactForm }) {
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const isMobile = useMediaQuery("(max-width:768px)");
  const location = useLocation();

  const getScrollbarWidth = () => {
    const outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.overflow = "scroll";
    outer.style.msOverflowStyle = "scrollbar";
    document.body.appendChild(outer);

    const inner = document.createElement("div");
    outer.appendChild(inner);

    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
  };

  const handleNavItemClick = (e, item) => {
    e.preventDefault();
    if (!isMobile) {
      // Do nothing on web view
      return;
    }
    setIsMenuOpen(false);
    setActiveSubmenu(null);
    setHoveredItem(null);
  };

  const handleMouseEnter = (menuKey) => {
    setIsMenuOpen(true);
    setActiveSubmenu(menuKey);
    setHoveredItem(menuKey);
  };

  const handleMouseLeave = () => {
    setIsMenuOpen(false);
    setActiveSubmenu(null);
    setHoveredItem(null);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    document.body.classList.toggle("body-blur", !isMobileMenuOpen);
    document.body.classList.toggle("mobile-menu-open", !isMobileMenuOpen);
    document.body.style.overflow = isMobileMenuOpen ? "auto" : "hidden";
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
    document.body.classList.remove("body-blur");
    document.body.classList.remove("mobile-menu-open");
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      window.requestAnimationFrame(() => {
        const width = getScrollbarWidth();
        setScrollbarWidth(width);
      });
    }
  }, [isMounted]);

  useEffect(() => {
    const mainContent = document.querySelector("main");
    if (isMenuOpen) {
      mainContent.classList.add("blur");
      document.body.style.overflow = "hidden";
      document.body.classList.add("submenu-open");
    } else {
      mainContent.classList.remove("blur");
      document.body.style.overflow = "";
      document.body.classList.remove("submenu-open");
    }
  }, [isMenuOpen]);

  const renderSubmenuContent = (submenuKey) => {
    const submenu = submenuData[submenuKey];
    if (!submenu) return null;

    return (
      <>
        {submenu.map((column, index) => (
          <div key={index} className={styles.submenuColumn}>
            <h3>{column.title}</h3>
            <ul>
              {column.items.map((item, itemIndex) => (
                <li key={itemIndex}>
                  <Link
                    to={item.link || "#"}
                    onClick={(e) => {
                      if (item.name === "Kontaktformular") {
                        openContactForm();
                      }
                      closeMobileMenu();
                      setIsMenuOpen(false);
                    }}
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </>
    );
  };

  const renderMobileSubmenu = (submenuKey) => {
    const submenu = submenuData[submenuKey];
    if (!submenu) return null;

    return (
      <Collapse in={activeSubmenu === submenuKey} timeout="auto" unmountOnExit>
        {submenu.map((column, index) => (
          <List component="div" disablePadding key={index}>
            <ListSubheader>{column.title}</ListSubheader>
            {column.items.map((item, itemIndex) => (
              <ListItem button key={itemIndex} onClick={closeMobileMenu}>
                <ListItemText>
                  <Link
                    to={item.link || "#"}
                    onClick={(e) => {
                      if (item.name === "Kontaktformular") {
                        openContactForm();
                      }
                      closeMobileMenu();
                      setIsMenuOpen(false);
                    }}
                  >
                    {item.name}
                  </Link>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        ))}
      </Collapse>
    );
  };

  const renderDesktopMenu = () => (
    <nav className={styles.navContainer}>
      <ul className={styles.navList}>
        {menuItems.map((item) => (
          <li
            key={item.key}
            className={`${styles.navItem} ${
              hoveredItem && hoveredItem !== item.key ? styles.dimmed : ""
            }`}
            onMouseEnter={() => handleMouseEnter(item.key)}
            onClick={(e) => handleNavItemClick(e, item)}
          >
            <span className={styles.navLink}>{item.name}</span>
          </li>
        ))}
      </ul>
    </nav>
  );

  const renderMobileMenu = () => (
    <Fade in={isMobileMenuOpen}>
      <div className={styles.mobileDrawer}>
        <List component="nav">
          {menuItems.map((item) => (
            <div key={item.key}>
              <ListItem
                button
                onClick={() => {
                  setActiveSubmenu(
                    activeSubmenu === item.key ? null : item.key
                  );
                }}
              >
                <ListItemText primary={item.name} />
              </ListItem>
              {renderMobileSubmenu(item.key)}
            </div>
          ))}
        </List>
      </div>
    </Fade>
  );

  return (
    <div
      className={`${styles.headerWrapper} ${
        isMenuOpen ? styles.expanded : ""
      } ${!isVisible ? styles.hidden : ""}`}
      onMouseLeave={handleMouseLeave}
      style={{ "--scrollbar-width": `${scrollbarWidth}px` }}
    >
      <header
        className={`${styles.header} ${
          isMenuOpen ? styles.submenuVisible : ""
        }`}
      >
        <div className={styles.headerContent}>
          <Link
            to="/"
            className={`${styles.logo} ${styles.logoLink}`}
            onClick={(e) => {
              window.scrollTo(0, 0);
              setIsMenuOpen(false);
              setActiveSubmenu(null);
              setHoveredItem(null);
              if (isMobile && isMobileMenuOpen) {
                closeMobileMenu();
              }
            }}
          >
            [d]
          </Link>
          {isMobile ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleMobileMenu}
              className={`${styles.hamburger} ${
                isMobileMenuOpen ? styles.closeIcon : ""
              }`}
            >
              {isMobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          ) : (
            location.pathname !== "/dashboard" && renderDesktopMenu()
          )}
          {!isMobile && (
            <i className={`fas fa-search ${styles.searchIcon}`}></i>
          )}
        </div>
      </header>
      {isMenuOpen && activeSubmenu && (
        <div className={`${styles.submenuPanel} ${styles.visible}`}>
          <div className={styles.submenuContent} key={activeSubmenu}>
            {renderSubmenuContent(activeSubmenu)}
          </div>
        </div>
      )}
      {isMobile && renderMobileMenu()}
    </div>
  );
}

export default Header;
