export const leistungenSlides = [
  {
    image:
      "https://images.unsplash.com/photo-1505778276668-26b3ff7af103?q=80&w=2322&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Digitale Beratung & Strategie",
    description:
      "Unterstützung bei der digitalen Umstellung Ihrer Geschäftsprozesse und -modelle.",
  },
  {
    image:
      "https://images.unsplash.com/photo-1581094651181-35942459ef62?q=80&w=2340&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Maßgeschneiderte Softwarelösungen",
    description:
      "Entwicklung individueller Software für Ihre spezifischen Branchenanforderungen.",
  },
  {
    image:
      "https://images.unsplash.com/photo-1509803874385-db7c23652552?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Cloud-Migration",
    description:
      "Sichere Überführung Ihrer Systeme in die Cloud und Optimierung für maximale Effizienz.",
  },
  {
    image:
      "https://images.unsplash.com/photo-1646617747563-4f080bddf282?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Prozessautomatisierung",
    description:
      "Steigerung der Effizienz durch Automatisierung wiederkehrender Geschäftsprozesse.",
  },
  {
    image:
      "https://images.unsplash.com/photo-1666875753105-c63a6f3bdc86?q=80&w=2346&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Datenanalyse & Business Intelligence",
    description:
      "Nutzen Sie die Kraft Ihrer Daten für fundierte Geschäftsentscheidungen.",
  },
  {
    image:
      "https://images.unsplash.com/photo-1585079374502-415f8516dcc3?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Cybersicherheit & Datenschutz",
    description:
      "Schutz Ihrer digitalen Assets und Einhaltung von Datenschutzbestimmungen.",
  },
];
