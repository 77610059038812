// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BrandCarousel_brandCarouselContainer__\\+jETo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--space-xxl);
  padding-bottom: var(--space-xxl);
  /* hide the overflow */
  overflow: hidden;
}

.BrandCarousel_textContainer__nGvyj {
  text-align: center;
  margin-bottom: 60px;
  color: #fff;
}

.BrandCarousel_textContainer__nGvyj h2 {
  font-size: 22px;
}

.BrandCarousel_textContainer__nGvyj p {
  font-size: 16px;
}

.BrandCarousel_brandCarousel__3rauR {
  display: flex;
  width: 100%;
  position: relative;
}

.BrandCarousel_brands__d7mi3 {
  display: flex;
  align-items: center;
  animation: BrandCarousel_sideToSide__X2y4O 30s linear infinite;
}

.BrandCarousel_subtext__Maf5C {
  font-size: 14px;
  color: #666;
  max-width: 80%;
  margin: 10px auto 0px;
  line-height: 1.5;
  text-align: center;
}

.BrandCarousel_brand__nbFl7 {
  flex: 0 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BrandCarousel_brand__nbFl7 img {
  height: 50px;
  width: auto;
  object-fit: contain;
}

@keyframes BrandCarousel_sideToSide__X2y4O {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/BrandCarousel.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,4BAA4B;EAC5B,gCAAgC;EAChC,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8DAAyC;AAC3C;;AAEA;EACE,eAAe;EACf,WAAW;EACX,cAAc;EACd,qBAAqB;EACrB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,2BAA2B;EAC7B;AACF","sourcesContent":[".brandCarouselContainer {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: var(--space-xxl);\n  padding-bottom: var(--space-xxl);\n  /* hide the overflow */\n  overflow: hidden;\n}\n\n.textContainer {\n  text-align: center;\n  margin-bottom: 60px;\n  color: #fff;\n}\n\n.textContainer h2 {\n  font-size: 22px;\n}\n\n.textContainer p {\n  font-size: 16px;\n}\n\n.brandCarousel {\n  display: flex;\n  width: 100%;\n  position: relative;\n}\n\n.brands {\n  display: flex;\n  align-items: center;\n  animation: sideToSide 30s linear infinite;\n}\n\n.subtext {\n  font-size: 14px;\n  color: #666;\n  max-width: 80%;\n  margin: 10px auto 0px;\n  line-height: 1.5;\n  text-align: center;\n}\n\n.brand {\n  flex: 0 0 auto;\n  padding: 0 20px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.brand img {\n  height: 50px;\n  width: auto;\n  object-fit: contain;\n}\n\n@keyframes sideToSide {\n  0% {\n    transform: translateX(0);\n  }\n  100% {\n    transform: translateX(-50%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brandCarouselContainer": `BrandCarousel_brandCarouselContainer__+jETo`,
	"textContainer": `BrandCarousel_textContainer__nGvyj`,
	"brandCarousel": `BrandCarousel_brandCarousel__3rauR`,
	"brands": `BrandCarousel_brands__d7mi3`,
	"sideToSide": `BrandCarousel_sideToSide__X2y4O`,
	"subtext": `BrandCarousel_subtext__Maf5C`,
	"brand": `BrandCarousel_brand__nbFl7`
};
export default ___CSS_LOADER_EXPORT___;
