// src/components/LayoutWrapper.js

import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import Footer from "./Footer";

function LayoutWrapper({ children, theme, toggleTheme, openContactForm }) {
  // Add openContactForm
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const prevScrollPos = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const isAtTop = currentScrollPos < 10;
      const isAtBottom = windowHeight + currentScrollPos >= documentHeight - 10;
      const isScrollingUp = prevScrollPos.current > currentScrollPos;

      setIsHeaderVisible(isAtTop || isScrollingUp);
      setIsFooterVisible(isAtBottom);
      prevScrollPos.current = currentScrollPos;
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    // Trigger an initial scroll event
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Header
        theme={theme}
        toggleTheme={toggleTheme}
        isVisible={isHeaderVisible}
        openContactForm={openContactForm} // Pass openContactForm to Header
      />
      <main>{children}</main>
      <Footer isVisible={isFooterVisible} openContactForm={openContactForm} />
    </>
  );
}

export default LayoutWrapper;
