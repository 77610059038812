export const menuItems = [
  { name: "Services", key: "leistungen" },
  // { name: "Lösungen", key: "softwareloesungen" },
  // { name: "Technologien", key: "technologien" },
  { name: "Produkte", key: "products" },
  { name: "Über Uns", key: "about" },
  { name: "Kontakt", key: "contact" },
];

export const submenuData = {
  leistungen: [
    {
      title: "Beratung",
      items: [
        { name: "Digitalisierungsberatung", link: "/digitalisierungsberatung" },
        { name: "Potenzialanalyse", link: "/potenzialanalyse" },
      ],
    },
    {
      title: "Softwareentwicklung",
      items: [
        {
          name: "Individualsoftware",
          link: "/individuelle-software",
        },
      ],
    },
    {
      title: "Datenanalyse",
      items: [
        { name: "Business Intelligence", link: "/business-intelligence" },
        // { name: "Data Governance", link: "/data-governance" },
      ],
    },
    // {
    //   title: "Cybersecurity",
    //   items: [
    //     { name: "Sicherheitsanalysen", link: "/sicherheitsanalysen" },
    //     { name: "Risikobewertungen", link: "/risikobewertungen" },
    //     { name: "Sicherheitsmaßnahmen", link: "/sicherheitsmassnahmen" },
    //   ],
    // },
    {
      title: "Support",
      items: [
        { name: "Schulungen", link: "/schulungen" },
        { name: "Technischer Support", link: "/technischer-support" },
      ],
    },
  ],
  // softwareloesungen: [
  //   {
  //     title: "Dokumentenmanagement",
  //     items: [
  //       {
  //         name: "Digitalisierung von Dokumenten",
  //         link: "/digitalisierung-von-dokumenten",
  //       },
  //       { name: "Elektronische Signaturen", link: "/elektronische-signaturen" },
  //     ],
  //   },
  //   {
  //     title: "Finanzautomatisierung",
  //     items: [
  //       {
  //         name: "Automatisierte Debitorenbuchung",
  //         link: "/automatisierte-debitorenbuchung",
  //       },
  //     ],
  //   },
  //   {
  //     title: "Digitale Geschäftsprozesse",
  //     items: [
  //       { name: "Workflow-Automatisierung", link: "/workflow-automatisierung" },
  //       { name: "Middleware-Lösungen", link: "/middleware-loesungen" },
  //     ],
  //   },
  // ],
  // technologien: [
  //   // {
  //   //   title: "Industrie 4.0",
  //   //   items: [
  //   //     { name: "Predictive Maintenance", link: "/predictive-maintenance" },
  //   //     { name: "Digitale Zwillinge", link: "/digitale-zwillinge" },
  //   //   ],
  //   // },
  //   {
  //     title: "Data Analytics",
  //     items: [
  //       { name: "Künstliche Intelligenz", link: "/kuenstliche-intelligenz" },
  //     ],
  //   },
  // ],
  about: [
    {
      title: "Unternehmen",
      items: [
        { name: "Unsere Geschichte", link: "/unsere-geschichte" },
        { name: "Vision und Mission", link: "/vision-und-mission" },
      ],
    },
    {
      title: "Team",
      items: [{ name: "Mitarbeiter", link: "/mitarbeiter" }],
    },
  ],
  contact: [
    {
      title: "Kontaktmöglichkeiten",
      items: [{ name: "Kontaktformular" }],
    },
    // {
    //   title: "Support",
    //   items: [{ name: "FAQ", link: "/faq" }],
    // },
  ],
  products: [
    {
      title: "Office",
      items: [
        { name: "eSignatur", link: "/esignatur" },
        { name: "E-Mail Archivierung", link: "/briefbox" },
        { name: "Dokumentenmanagement", link: "/dokumentenmanagement" },
      ],
    },
    {
      title: "Buchhaltung",
      items: [{ name: "Debitorenbuchhaltung", link: "/drawdetect" }],
    },
    {
      title: "Maschinenbau",
      items: [{ name: "DrawDetect", link: "/drawdetect" }],
    },
  ],
};
