import React from "react";
import PropTypes from "prop-types";
import styles from "./ArticlePage.module.css";

function ArticlePage({ title, subtitle, date, image, sections }) {
  const months = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day}. ${month}, ${year}`;
  };

  return (
    <div className={styles.articlePage}>
      <header className={styles.header}>
        {date && <p className={styles.date}>{formatDate(date)}</p>}
        <h1 className={styles.title}>{title}</h1>
        {subtitle && <h2 className={styles.subtitle}>{subtitle}</h2>}
        {image && <img src={image} alt={title} className={styles.image} />}
      </header>
      <main className={styles.content}>
        {sections.map((section, index) => (
          <div key={index} className={styles.section}>
            {section.heading && (
              <h2 className={styles.heading}>{section.heading}</h2>
            )}
            {section.content && (
              <div
                className={styles.sectionContent}
                dangerouslySetInnerHTML={{ __html: section.content }}
              />
            )}
          </div>
        ))}
      </main>
    </div>
  );
}

ArticlePage.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  date: PropTypes.string,
  image: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      content: PropTypes.string,
    })
  ).isRequired,
};

export default ArticlePage;
