import React, { useRef, useEffect } from "react";
import styles from "./BrandCarousel.module.css";

const brands = [
  "https://i.ibb.co/6HynNKS/wordpress.png",
  "https://i.ibb.co/41kkqNJ/cnn.png",
  "https://i.ibb.co/g6W04GJ/google.png",
  "https://i.ibb.co/CsFyzY8/hbo.png",
  "https://i.ibb.co/T20Ssx4/hulu.png",
  "https://i.ibb.co/ncvNQSB/ibm.png",
  "https://i.ibb.co/yPzSXPL/microsoft.png",
];

function BrandCarousel() {
  const carouselRef = useRef(null);

  useEffect(() => {
    const carousel = carouselRef.current;
    const clonedBrands = carousel.cloneNode(true);
    carousel.parentNode.appendChild(clonedBrands);
  }, []);

  return (
    <div className={styles.brandCarouselContainer}>
      <div className={styles.textContainer}>
        <h2>Diese Unternehmen vertrauen auf unsere Expertise:</h2>
        <p className={styles.subtext}>
          Wir liefern maßgeschneiderte Lösungen, die Unternehmen jeder Größe
          voranbringen. Unsere Kunden schätzen unsere innovative
          Herangehensweise und unseren Fokus auf messbare Ergebnisse.
        </p>
      </div>
      <div className={styles.brandCarousel}>
        <div className={styles.brands} ref={carouselRef}>
          {brands.map((brand, index) => (
            <div className={styles.brand} key={index}>
              <img src={brand} alt={`Brand ${index}`} />
            </div>
          ))}
          {brands.map((brand, index) => (
            <div className={styles.brand} key={`${index}-clone`}>
              <img src={brand} alt={`Brand clone ${index}`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BrandCarousel;
