// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/ContentPage.module.css */
.ContentPage_contentPage__3RyDo {
  color: var(--color-text);
  padding: var(--space-lg);
  margin-top: var(--space-lg); /* Add this line to shift content down */
}

.ContentPage_title__RU\\+m2 {
  font-size: var(--font-size-xlarge);
  margin-bottom: var(--space-md);
}

.ContentPage_section__2TuKX {
  margin-bottom: var(--space-lg);
}

.ContentPage_heading__4Vlj4 {
  font-size: var(--font-size-large);
  margin-bottom: var(--space-sm);
}

.ContentPage_content__Pz6mk {
  font-size: var(--font-size-base);
  margin-bottom: var(--space-md);
  line-height: var(--line-height-base);
}

.ContentPage_image__2AzRy {
  width: 100%;
  max-width: 600px;
  height: auto;
  margin-bottom: var(--space-md);
  border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ContentPage.module.css"],"names":[],"mappings":"AAAA,0CAA0C;AAC1C;EACE,wBAAwB;EACxB,wBAAwB;EACxB,2BAA2B,EAAE,wCAAwC;AACvE;;AAEA;EACE,kCAAkC;EAClC,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,iCAAiC;EACjC,8BAA8B;AAChC;;AAEA;EACE,gCAAgC;EAChC,8BAA8B;EAC9B,oCAAoC;AACtC;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,8BAA8B;EAC9B,mBAAmB;AACrB","sourcesContent":["/* src/components/ContentPage.module.css */\n.contentPage {\n  color: var(--color-text);\n  padding: var(--space-lg);\n  margin-top: var(--space-lg); /* Add this line to shift content down */\n}\n\n.title {\n  font-size: var(--font-size-xlarge);\n  margin-bottom: var(--space-md);\n}\n\n.section {\n  margin-bottom: var(--space-lg);\n}\n\n.heading {\n  font-size: var(--font-size-large);\n  margin-bottom: var(--space-sm);\n}\n\n.content {\n  font-size: var(--font-size-base);\n  margin-bottom: var(--space-md);\n  line-height: var(--line-height-base);\n}\n\n.image {\n  width: 100%;\n  max-width: 600px;\n  height: auto;\n  margin-bottom: var(--space-md);\n  border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentPage": `ContentPage_contentPage__3RyDo`,
	"title": `ContentPage_title__RU+m2`,
	"section": `ContentPage_section__2TuKX`,
	"heading": `ContentPage_heading__4Vlj4`,
	"content": `ContentPage_content__Pz6mk`,
	"image": `ContentPage_image__2AzRy`
};
export default ___CSS_LOADER_EXPORT___;
