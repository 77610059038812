import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import styles from "./Footer.module.css";

function Footer({ isVisible, openContactForm }) {
  return (
    <footer className={`${styles.footer} ${isVisible ? styles.show : ""}`}>
      <div className={styles.footerContent}>
        <div className={styles.footerLeft}>
          <span className={styles.footerText}>
            datacook © {new Date().getFullYear()}
          </span>
        </div>
        <div className={styles.footerCenter}>
          <a href="/impressum" className={styles.footerLink}>
            Impressum
          </a>
          <span className={styles.footerSeparator}>|</span>
          <a href="/impressum" className={styles.footerLink}>
            Datenschutz
          </a>
          <span className={styles.footerSeparator}>|</span>
          <a onClick={openContactForm} className={styles.footerLink}>
            Kontakt
          </a>
        </div>
        <div className={styles.footerRight}>
          <a
            href="https://github.com/bandundu"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.socialLink}
          >
            <FontAwesomeIcon icon={faGithub} />
          </a>
          <a
            href="https://www.linkedin.com/in/charlesmupende/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.socialLink}
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
