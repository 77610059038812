// contents/slidesData.js
export const slides = [
  {
    image:
      "https://images.unsplash.com/photo-1451187580459-43490279c0fa?q=80&w=1472&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Intelligente Prozessautomatisierung",
    content:
      "Steigern Sie Ihre Effizienz und reduzieren Sie Kosten durch unsere maßgeschneiderten Automatisierungslösungen.",
    button: "Mehr erfahren",
  },
  {
    image:
      "https://images.unsplash.com/flagged/photo-1562599838-8cc871c241a5?q=80&w=1760&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Datengesteuerte Entscheidungsfindung",
    content:
      "Entdecken Sie wertvolle Einblicke in Ihre Geschäftsdaten und treffen Sie fundierte Entscheidungen mit unserer leistungsstarken Analysesoftware.",
    button: "Mehr erfahren",
  },
  {
    image:
      "https://images.unsplash.com/photo-1524514587686-e2909d726e9b?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Maßgeschneiderte Digitalisierungsstrategie",
    content:
      "Entwickeln Sie mit datacook eine zukunftssichere digitale Roadmap, die perfekt auf Ihr Unternehmen zugeschnitten ist.",
    button: "Mehr erfahren",
  },
];
