import React, { useEffect } from "react";
import "./ClickSpark"; // Ensure the path is correct based on your project structure
import "./ClickSpark.css"; // Ensure the path is correct based on your project structure

const ClickSparkWrapper = () => {
  useEffect(() => {
    const spark = document.querySelector("click-spark");

    return () => {
      // Clean up event listeners if needed
    };
  }, []);

  return (
    <div>
      <click-spark></click-spark>
    </div>
  );
};

export default ClickSparkWrapper;
