// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ClickSpark.css */

/* Demo styles */
* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  display: grid;
  place-items: end start;
  font-family: system-ui, sans-serif;
}

label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  -webkit-user-select: none;
          user-select: none;
}

input[type="color"] {
  --size: 2em;
  flex-shrink: 0;
  appearance: none;
  box-sizing: border-box;
  cursor: pointer;
  height: var(--size);
  width: var(--size);
  padding: 0;
  border: 0;
  border-radius: 50%;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type="color"]::-webkit-color-swatch {
  border-radius: 50%;
}

input[type="color"]::-moz-color-swatch {
  border-radius: 50%;
}

::-webkit-color-swatch,
::-moz-color-swatch {
  border-color: transparent;
}

/* ClickSpark styles */
click-spark {
  z-index: 9999; /* Highest level */
}

click-spark svg {
  pointer-events: none;
  position: absolute;
  rotate: -20deg;
  stroke: var(--click-spark-color, currentcolor);
  z-index: 9999; /* Highest level */
}

click-spark line {
  stroke-dasharray: 30;
  stroke-dashoffset: 30;
  transform-origin: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/ClickSpark.css"],"names":[],"mappings":"AAAA,mBAAmB;;AAEnB,gBAAgB;AAChB;EACE,sBAAsB;AACxB;;AAEA;;EAEE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,gBAAgB;EAChB,sBAAsB;EACtB,eAAe;EACf,mBAAmB;EACnB,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;;EAEE,yBAAyB;AAC3B;;AAEA,sBAAsB;AACtB;EACE,aAAa,EAAE,kBAAkB;AACnC;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,cAAc;EACd,8CAA8C;EAC9C,aAAa,EAAE,kBAAkB;AACnC;;AAEA;EACE,oBAAoB;EACpB,qBAAqB;EACrB,wBAAwB;AAC1B","sourcesContent":["/* ClickSpark.css */\n\n/* Demo styles */\n* {\n  box-sizing: border-box;\n}\n\nhtml,\nbody {\n  height: 100%;\n}\n\nbody {\n  display: grid;\n  place-items: end start;\n  font-family: system-ui, sans-serif;\n}\n\nlabel {\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n  user-select: none;\n}\n\ninput[type=\"color\"] {\n  --size: 2em;\n  flex-shrink: 0;\n  appearance: none;\n  box-sizing: border-box;\n  cursor: pointer;\n  height: var(--size);\n  width: var(--size);\n  padding: 0;\n  border: 0;\n  border-radius: 50%;\n}\n\ninput[type=\"color\"]::-webkit-color-swatch-wrapper {\n  padding: 0;\n}\n\ninput[type=\"color\"]::-webkit-color-swatch {\n  border-radius: 50%;\n}\n\ninput[type=\"color\"]::-moz-color-swatch {\n  border-radius: 50%;\n}\n\n::-webkit-color-swatch,\n::-moz-color-swatch {\n  border-color: transparent;\n}\n\n/* ClickSpark styles */\nclick-spark {\n  z-index: 9999; /* Highest level */\n}\n\nclick-spark svg {\n  pointer-events: none;\n  position: absolute;\n  rotate: -20deg;\n  stroke: var(--click-spark-color, currentcolor);\n  z-index: 9999; /* Highest level */\n}\n\nclick-spark line {\n  stroke-dasharray: 30;\n  stroke-dashoffset: 30;\n  transform-origin: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
