import {
  faCoffee,
  faBook,
  faChartLine,
  faCogs,
  faLightbulb,
  faTasks,
} from "@fortawesome/free-solid-svg-icons";

export const steps = [
  {
    title: "Unverbindliche Erstberatung",
    description:
      "Ein Experte von Datacook berät Sie in einem unverbindlichen Erstgespräch Online oder vor Ort zu unseren Leistungen.",
    icon: faCoffee,
  },
  {
    title: "Unverbindliche Potenzialanalyse",
    description:
      "Im nächsten Schritt werden die geschäftlichen und technischen Herausforderungen Ihres Unternehmens analysiert.",
    icon: faBook,
  },
  {
    title: "Business Case Evaluierung",
    description:
      "Umfassende Analyse der Geschäftsanforderungen. Der Anwendungsbereich der empfohlenen Lösung wird definiert.",
    icon: faChartLine,
  },
  {
    title: "Solution Design",
    description:
      "Eingehende Analyse von Prozessen, funktionalen Anforderungen, IT-Landschaft und Organisation. Entwicklung eines Lösungskonzepts.",
    icon: faCogs,
  },
  {
    title: "Prototype Implementation",
    description:
      "Definition des Prototypumfangs, der im Standard implementiert werden soll. Einführung und Inbetriebnahme des Prototyps.",
    icon: faLightbulb,
  },
  {
    title: "Full Scope Implementation",
    description:
      "Vollständige Implementierung von der Projektplanung bis zum Go-Live und Hypercare-Support. Entwicklung zusätzlicher Funktionalitäten und Integration mit anderen Systemen.",
    icon: faTasks,
  },
];
