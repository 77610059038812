import React from "react";
import PropTypes from "prop-types";
import styles from "./ContentPage.module.css";

function ContentPage({ title, sections }) {
  return (
    <div className={styles.contentPage}>
      <h1 className={styles.title}>{title}</h1>
      {sections.map((section, index) => (
        <div key={index} className={styles.section}>
          {section.heading && (
            <h2 className={styles.heading}>{section.heading}</h2>
          )}
          {section.content && (
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: section.content }}
            />
          )}
          {section.image && (
            <img
              src={section.image}
              alt={section.alt}
              className={styles.image}
            />
          )}
        </div>
      ))}
    </div>
  );
}

ContentPage.propTypes = {
  title: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      content: PropTypes.string,
      image: PropTypes.string,
      alt: PropTypes.string,
    })
  ).isRequired,
};

export default ContentPage;
