// src/contents/potenzialanalyseData.js

export const potenzialanalyseContent = {
  title: "Potenzialanalyse",
  sections: [
    {
      heading: "Was ist eine Potenzialanalyse?",
      content: `
          Eine Potenzialanalyse ist ein systematisches Verfahren zur Ermittlung und Bewertung der Stärken und Schwächen einer Person, eines Teams oder einer Organisation. Ziel ist es, die vorhandenen Potenziale und Entwicklungsmöglichkeiten zu identifizieren und gezielt zu fördern.
        `,
    },
    {
      heading: "Ziele der Potenzialanalyse",
      content: `
          Die Potenzialanalyse verfolgt verschiedene Ziele, darunter:
          <ul>
            <li>Ermittlung der individuellen Stärken und Schwächen</li>
            <li>Erkennung von Entwicklungsmöglichkeiten</li>
            <li>Förderung der persönlichen und beruflichen Entwicklung</li>
            <li>Optimierung der Teamzusammenstellung</li>
            <li>Steigerung der Unternehmensleistung</li>
          </ul>
        `,
    },
    {
      heading: "Methoden der Potenzialanalyse",
      content: `
          Es gibt verschiedene Methoden zur Durchführung einer Potenzialanalyse, wie zum Beispiel:
          <ul>
            <li>Interviews</li>
            <li>Fragebögen</li>
            <li>Assessment-Center</li>
            <li>360-Grad-Feedback</li>
            <li>Psychometrische Tests</li>
          </ul>
        `,
    },
    {
      heading: "Vorteile der Potenzialanalyse",
      content: `
          Eine Potenzialanalyse bietet zahlreiche Vorteile, darunter:
          <ul>
            <li>Gezielte Förderung der Mitarbeiter</li>
            <li>Erhöhung der Mitarbeiterzufriedenheit</li>
            <li>Verbesserung der Teamarbeit</li>
            <li>Optimierung der Personalentwicklung</li>
            <li>Steigerung der Unternehmensleistung</li>
          </ul>
        `,
    },
    {
      heading: "Fazit",
      content: `
          Die Potenzialanalyse ist ein wertvolles Instrument zur Identifikation und Förderung von Potenzialen in Personen und Organisationen. Durch den Einsatz geeigneter Methoden können Stärken und Entwicklungsmöglichkeiten gezielt ermittelt und genutzt werden, um die persönliche und berufliche Entwicklung zu fördern und die Unternehmensleistung zu steigern.
        `,
    },
  ],
};
