import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./NotFoundPage.module.css";
import Particles404 from "./Particles404";

const NotFoundPage = () => {
  useEffect(() => {
    // Add class to body to prevent scrolling
    document.body.classList.add(styles.noScroll);

    // Clean up function to reset the style
    return () => {
      document.body.classList.remove(styles.noScroll);
    };
  }, []);

  return (
    <div className={styles.notFoundWrapper}>
      {/* <Particles404 /> */}
      <div className={styles.content}>
        <img
          src="https://images.unsplash.com/photo-1663465374413-83cba00bff6f?q=80&w=1480&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" // Replace with your image link
          alt="Lost Data"
          className={styles.image}
        />
        <h1 className={styles.title}>404</h1>
        <p className={styles.subtitle}>
          Oops! Hier scheint ein Datenschnipsel verloren gegangen zu sein.
        </p>
        <Link to="/" className={styles.homeLink}>
          Zurück zur Startseite
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
