import React, { useEffect, useRef } from "react";
import styles from "./ContactForm.module.css";

function ContactForm({ isOpen, onClose }) {
  const modalRef = useRef(null);

  const handleClose = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClose);
      document.addEventListener("keydown", handleKeyDown);
      document.body.style.overflow = "hidden"; // Prevent scrolling
    } else {
      document.removeEventListener("mousedown", handleClose);
      document.removeEventListener("keydown", handleKeyDown);
      document.body.style.overflow = ""; // Re-enable scrolling
    }
    return () => {
      document.removeEventListener("mousedown", handleClose);
      document.removeEventListener("keydown", handleKeyDown);
      document.body.style.overflow = ""; // Clean up on unmount
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const data = {
      firstName: formData.get("firstName"),
      lastName: formData.get("lastName"),
      position: formData.get("position"),
      companyName: formData.get("companyName"),
      email: formData.get("email"),
      phoneNumber: formData.get("phoneNumber"),
      topic: formData.get("topic"),
      message: formData.get("message"),
    };

    try {
      const response = await fetch("https://www.datacook.de/api/submit-form/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Network response was not ok");
      }

      const result = await response.json();
      alert(result.message);
      form.reset();
      onClose();
    } catch (error) {
      console.error("Fehler beim Senden der Nachricht:", error);
      alert("Fehler beim Senden der Nachricht: " + error.message);
    }
  };

  return (
    <div className={`${styles.overlay} ${isOpen ? styles.open : ""}`}>
      <div className={styles.modal} ref={modalRef}>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        <div className={styles.modalContent}>
          <div className={styles.formContainer}>
            <h2>Kontaktieren Sie uns!</h2>
            <form className={styles.form} onSubmit={handleSubmit}>
              <div className={styles.formRow}>
                <div className={styles.formGroup}>
                  <label htmlFor="firstName">Vorname*</label>
                  <input type="text" id="firstName" name="firstName" required />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="lastName">Nachname*</label>
                  <input type="text" id="lastName" name="lastName" required />
                </div>
              </div>
              <div className={styles.formRow}>
                <div className={styles.formGroup}>
                  <label htmlFor="position">Ihre Position</label>
                  <input type="text" id="position" name="position" />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="companyName">Unternehmensname</label>
                  <input type="text" id="companyName" name="companyName" />
                </div>
              </div>
              <div className={styles.formRow}>
                <div className={styles.formGroup}>
                  <label htmlFor="email">E-Mail*</label>
                  <input type="email" id="email" name="email" required />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="phoneNumber">Telefonnummer</label>
                  <input type="text" id="phoneNumber" name="phoneNumber" />
                </div>
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="topic">
                  Bei welchem Thema können wir Sie unterstützen?*
                </label>
                <input type="text" id="topic" name="topic" required />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="message">
                  Inwieweit können Sie ihre Problemstellung beschreiben?
                </label>
                <textarea id="message" name="message"></textarea>
              </div>
              <button type="submit" className={styles.submitButton}>
                Senden
              </button>
            </form>
          </div>
          <div className={styles.infoContainer}>
            <h3>Hauptstandort</h3>
            <p>datacook</p>
            <p>Alleenstraße 15</p>
            <p>74372 Sersheim</p>
            <a href="mailto:info@datacook.de">E-Mail senden</a>
            <p>
              Tel: <a href="tel:+491794157744">+49 179 415 7744</a>
            </p>
            {/* <div className={styles.socialLinks}>
              <a href="#">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="#">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#">
                <i className="fab fa-youtube"></i>
              </a>
            </div> */}
            <p className={styles.legalText}>
              Bei Datacook nutzen wir Ihre Kontaktdaten, um Sie über unsere
              neuesten Produkte und Dienstleistungen zu informieren. Keine
              Sorge, Sie können sich jederzeit von unseren Updates abmelden.
              Mehr über unsere Datenschutzrichtlinien und unser Engagement für
              Ihre Privatsphäre erfahren Sie in unseren Datenschutzbestimmungen.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
