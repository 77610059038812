export const digitalisierungsberatungContent = {
  title: "Digitalisierungsberatung",
  subtitle: "Warum dies wichtig für Ihr Unternehmen ist",
  date: "July 4, 2024",
  image:
    "https://images.unsplash.com/photo-1451187580459-43490279c0fa?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", // use the uploaded image path
  sections: [
    {
      heading: "Warum ist Digitalisierungsberatung wichtig?",
      content: `
          Die Digitalisierung ist ein wesentlicher Treiber für Innovation, Effizienz und Wettbewerbsfähigkeit in der heutigen Geschäftswelt. Unternehmen, die ihre Prozesse digitalisieren, können schneller auf Marktveränderungen reagieren, Kosten reduzieren und neue Geschäftsmöglichkeiten erschließen. Unsere Digitalisierungsberatung hilft Unternehmen, diese Transformation erfolgreich zu gestalten und sich für die Zukunft zu rüsten.
        `,
    },
    {
      heading: "Vorteile der Digitalisierung",
      content: `
          Die Digitalisierung bietet zahlreiche Vorteile für Unternehmen:
          <ul>
            <li>Erhöhung der Effizienz und Produktivität</li>
            <li>Verbesserung der Kundenerfahrung</li>
            <li>Optimierung der Geschäftsprozesse</li>
            <li>Reduzierung von Kosten und Fehlern</li>
            <li>Ermöglichung von datengetriebenen Entscheidungen</li>
          </ul>
        `,
    },
    {
      heading: "Wie wird die Digitalisierung bewerkstelligt?",
      content: `
          Die erfolgreiche Digitalisierung erfordert einen systematischen Ansatz:
          <ul>
            <li>Analyse der aktuellen Prozesse und IT-Infrastruktur</li>
            <li>Definition einer klaren Digitalisierungsstrategie</li>
            <li>Implementierung moderner Technologien und Lösungen</li>
            <li>Schulung und Einbindung der Mitarbeiter</li>
            <li>Kontinuierliche Überwachung und Optimierung der Prozesse</li>
          </ul>
        `,
    },
    {
      heading: "Hürden bei der Digitalisierung",
      content: `
          Die Digitalisierung kann mit verschiedenen Herausforderungen verbunden sein:
          <ul>
            <li>Widerstand gegen Veränderungen</li>
            <li>Hohe Investitionskosten</li>
            <li>Sicherheits- und Datenschutzbedenken</li>
            <li>Komplexität der Integration neuer Technologien</li>
            <li>Notwendigkeit der kontinuierlichen Weiterbildung</li>
          </ul>
        `,
    },
    {
      heading: "Wie DataCook Sie unterstützt",
      content: `
          Bei DataCook bieten wir umfassende Unterstützung, um Ihr Unternehmen erfolgreich zu digitalisieren:
          <ul>
            <li>Individuelle Beratung und maßgeschneiderte Lösungen</li>
            <li>Erfahrene Experten in verschiedenen Technologiebereichen</li>
            <li>Begleitung von der Planung bis zur Umsetzung und darüber hinaus</li>
            <li>Schulung und Change Management für Ihre Mitarbeiter</li>
            <li>Kontinuierliche Betreuung und Optimierung der Lösungen</li>
          </ul>
          Kontaktieren Sie uns, um mehr darüber zu erfahren, wie wir Ihnen helfen können, die Digitalisierung in Ihrem Unternehmen voranzutreiben.
        `,
    },
  ],
};
